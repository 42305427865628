import React from "react"

import cx from "classnames"
import * as s from "./hr.module.sass"

const Hr = (props) => {
  const { parentClass, isLeft } = props

  const componentClass = cx({
    [s.component]: true,
    [s.isLeft]: isLeft,
    [parentClass]: parentClass,
  })

  return (
    <div className={componentClass}>
      <hr />
    </div>
  )
}

export default Hr
