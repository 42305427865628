import React from "react"

import cx from "classnames"
import * as s from "./buttons.module.sass"

const Buttons = props => {
  const {
    children,
    parentClass,
  }
  = props;

  return (
    <div className={cx(s.buttons, parentClass)}>
      {children}
    </div>
  )
}

export default Buttons
