import React from "react"

import Title from "components/elements/title"
import Hr from "components/elements/hr"

import cx from "classnames"
import * as s from "./heading.module.sass"

const Heading = props => {
  const {
    surtitle,
    title,
    subtitle,
    version = "",
    parentClass,
  } = props;

  return (
    <div className={cx(s.component, parentClass, version && s[version])}>
      {surtitle && <Title light parentClass={s.surtitle}>
        {surtitle}
      </Title>}

      {title && <Title parentClass={s.title}>
        {title}
      </Title>}

      {subtitle && <Title light parentClass={s.subtitle}>
        {subtitle}
      </Title>}

      <Hr parentClass={s.hr} />
    </div>
  )
}

export default Heading
