import React from "react"

import Layout from "layouts/default"
import ContentPage from "components/components/content-page"

const policyMarkdown = require('data/privacy-policy.md');

const PrivacyPolicy = () => {
  return (
    <Layout>
      <ContentPage
        surtitle="Netspeak Games"
        title="Privacy Policy"
        content={policyMarkdown.default}
        />
    </Layout>
  )
}

export default PrivacyPolicy
