import React from "react"
import { Helmet } from "react-helmet"

import Buttons from "components/form/buttons"
import Button from "components/form/button"
import Heading from "components/elements/heading"
import Content from "components/elements/content"
import * as s from "./content-page.module.sass"

const ContentPage = (props) => {
  const { surtitle, title, content } = props

  return (
    <div className={s.component}>
      <Helmet>
        <title>{title} - Netspeak Games</title>
      </Helmet>

      <Buttons parentClass={s.buttons}>
        <Button outlined to={`${process.env.SITE_URL}/`}>
          Back home
        </Button>
      </Buttons>

      <Heading parentClass={s.heading} surtitle={surtitle} title={title} />

      <Content parentClass={s.content} content={content} />
    </div>
  )
}

export default ContentPage
