import React from "react"

import cx from "classnames"
import * as s from "./content.module.sass"

const Content = (props) => {
  const { parentClass, light = false, content, children } = props

  const componentClass = cx({
    [s.component]: true,
    [s.light]: light,
    [parentClass]: parentClass,
  })

  if (children) {
    return <div className={componentClass}>{children}</div>
  }

  return (
    <div
      className={componentClass}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default Content
