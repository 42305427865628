import React from "react"

import cx from "classnames"
import * as s from "./title.module.sass"

const Title = (props) => {
  const {
    children,
    level = "3",
    light = false,
    raw = false,
    parentClass,
  } = props;

  const CustomTag = level === "0" ? `p` : `h${level}`;

  const componentClass = cx({
    [s.component]: true,
    [s.strong]: !light,
    [s.light]: light,
    [parentClass]: parentClass,
  });

  return (
    <CustomTag className={componentClass}>
      {raw ? <span dangerouslySetInnerHTML={{__html: children}} /> : children}
    </CustomTag>
  )
}

export default Title
